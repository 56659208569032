// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
require("lib/hotkeys")

document.addEventListener("turbolinks:before-visit", ()=>{
  hotkeys.unbind();
});

document.addEventListener("turbolinks:load", ()=> {
  
  // Auto-height text area
  const tx = document.getElementsByTagName('textarea');
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
    tx[i].addEventListener("input", OnInput, false);
  }
  
  function OnInput() {
    this.style.height = 'auto';
    this.style.height = (this.scrollHeight) + 'px';
  }
  // end auto-height text area
  
  
  // CMD+Return hotkey
  if (document.querySelector("textarea") != null) {
    document.querySelector("textarea").addEventListener('keydown', (e)=>{
      if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {
        e.target.form.submit()
      }
    });
  }
  
  // Escape
  (function () {
    window.onkeydown = function (e) {
      if (e.code === 'Escape') {
        const focused = document.activeElement;
        const elems = ['INPUT', 'TEXTAREA'];
        if (elems.includes(focused.tagName)) {
          focused.blur();
        }
      }
    };
  }());
  
  // hotkeys
  hotkeys('e,/,n,h,1,2', function (event, handler){
    if (window.spreadSheetIsFocus == true) return false

    switch (handler.key) {
      case '/':
        let searchForm = document.querySelector(".search-form");
        let searchElement = document.querySelector(".search-form input.search");
        searchElement.focus();
        event.preventDefault();
        break;
      case '1':
      case 'h':
        Turbolinks.visit("/entries/");
        event.preventDefault();
        break;
      case '2':
      case 'n':
        Turbolinks.visit("/entries/new");
        event.preventDefault();
        break;
      case 'e':
        var href = document.querySelector("a.edit-link").href;
        Turbolinks.visit(href);
        break;
      }
    }
  );
  
})

